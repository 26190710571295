.float-label {
    position: relative;
    padding: 0.6rem 0 0;

    input {

        outline: none;
    }

    .ant-select {
        width: 100%;

        &-selector {
            @extend %floating-label;
            box-shadow: none !important;
        }
    }

    .ant-picker {
        width: 100%;
        box-shadow: none;
    }
}

.label {
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    font-family: $rubik-font;
    color: #878787;
    font-size: 0.9rem;
}

.label-float {
    top: -5px;
    font-size: 12px;
}

.float-control {
    @extend %floating-label;
    color: $secondary-color;

    &::placeholder {
        font-family: $rubik-font;
    }
}

.float-control:focus {
    box-shadow: none;
}

%floating-label {
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0;
    font-family: $rubik-font;
}