.permission {
  &-title {
    border-bottom: 1px solid #00000029;
    padding-bottom: 0.4rem;

    h6 {
      color: #333;
      font-family: $rubik-font;
      font-size: 14px;
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: 3px;
        background-color: $primary-color;
        position: absolute;
        bottom: -9px;
        left: 0;
      }
    }
  }
}

.form-role {
  height: 100%;
  overflow: auto;
}
