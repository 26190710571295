.full-width-container {
    // height: calc(100vh - 130px);
    @apply container mx-auto max-w-full px-14;
    @media (max-width: 767px) {
        @apply px-4;
    }
    &.course-details {
        height: auto;
    }
    &.announcement {
        // height: calc(100vh - 150px);
    }
}
