@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

$Rubik: "Rubik", sans-serif;

$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$extraBold: 800;
$blackBold: 900;

.probation {
  position: relative;
  .sheet {
    position: relative;
    width: 90%;
    max-width: 1228px;
    background: #fff;
    margin: 2rem auto;
    padding: 2% 4%;
    display: block;
  }

  .main {
    display: flex;
  }

  .main-content {
    clear: both;
    overflow: hidden;

    .logo-header {
      display: flex;
      margin-bottom: 20px;

      h1 {
        margin: auto;
      }
    }
  }

  h1 {
    margin-bottom: 15px;
    color: #1f3c66;
    font-size: 30px !important;
    letter-spacing: 1.75px;
    font-weight: $semiBold;
    text-align: center;
    font-family: $Rubik;
    display: block;
  }

  ul {
    padding-top: 3rem;
    padding-left: 0;
    width: 50% !important;

    li {
      padding-top: 1.5rem;
      color: #595959;
      font-size: 18px;
      letter-spacing: 1.2px;
      display: flex;
      font-weight: 400;

      span {
        min-width: 54%;
        color: #1f3c66;
        font-weight: 500;
        padding-right: 20px;
      }

      .dept {
        max-width: 55%;
        color: #595959;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  ul:nth-child(2) {
    padding-left: 5%;
  }

  .paragraph {
    color: #595959;
    font-size: 17px;
    padding-top: 3rem;
    letter-spacing: 1px;
    line-height: 1.8;
    text-align: justify;
  }
}

@media (max-width: 1024px) {
  .probation {
    ul {
      width: 100% !important;
      padding-top: 1rem;
    }
    ul:nth-child(2) {
      padding-left: 0%;
    }
  }
}
