.employee-leave {
  margin-top: 35px;
  .popover {
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    padding: 20px;
  }
  h6 {
    color: #7d7d7d;
    font-size: 14px;
    margin-bottom: 13px;
  }
  ul {
    li {
      padding: 5px;
      color: #ffffff;
      font-size: 16px;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 10px;
      cursor: pointer;
      &:nth-child(1) {
        background-color: #aa347b;
      }
      &:nth-child(2) {
        background-color: #2c5ba7;
      }
      &:nth-child(3) {
        background-color: #0ebde0;
      }
      &:nth-child(4) {
        background-color: #ffe7f5;
        color: #aa347b;
      }
      &:nth-child(5) {
        background-color: #aa347b;
      }
      &:nth-child(6) {
        background-color: #2c5ba7;
      }
      &:nth-child(7) {
        background-color: #0ebde0;
      }
      &:nth-child(8) {
        background-color: #ffe7f5;
        color: #aa347b;
      }
      &:nth-child(9) {
        background-color: #aa347b;
      }
    }
  }
}
.employee-attendance {
  margin: auto 0;

  .grid {
    width: 100%;
    margin: 0;
    gap: 15px;
    @media (max-width: 1340px) {
      gap: 8;
    }
    div {
      h6 {
        color: #7d7d7d;
        font-size: 14px;
        margin-bottom: 13px;
        @media (max-width: 1340px) {
          font-size: 13px;
        }
        @media (max-width: 1260px) {
          font-size: 12px;
        }
      }
      p {
        padding: 8px 24px;
        text-align: center;
        border-radius: 5px;
        width: 125px;
        height: 50px;
        @media (max-width: 1380px) {
          width: 90px;
        }
        span {
          font-size: 27px;
          @media (max-width: 1340px) {
            font-size: 18px;
          }
        }
      }
      &:nth-child(1) {
        p {
          background-color: #fef0f5;
          color: #d34a7c;
        }
      }
      &:nth-child(2) {
        p {
          background-color: #fef2f9;
          color: #aa347b;
        }
      }
      &:nth-child(3) {
        p {
          background-color: #f0f7ff;
          color: #2c5ba7;
        }
      }
      &:nth-child(4) {
        p {
          background-color: #eefcff;
          color: #0ebde0;
        }
      }
    }
    .attendance {
      display: flex;
      width: 50%;
      flex: none;
      flex-wrap: wrap;
      gap: 6%;
      .in,
      .out {
        width: 47%;
        border-radius: 4px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .top {
          text-align: center;
          display: block;
          position: absolute;
          top: 0;
          padding: 0.4rem;
          width: 100%;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          color: #ffffff;
          line-height: 1;
        }
        .count {
          padding-top: 26px;
        }
      }
      .in {
        background-color: #fff2f7;
        .top {
          background-color: #d34a7c;
        }
        .count {
          color: #d34a7c;
        }
      }
      .out {
        background-color: #e6efff;
        .top {
          background-color: #2c5ba7;
        }
        .count {
          color: #2c5ba7;
        }
      }
      .total-in-per {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 4px;
        background-color: #f5f5f5;
      }
    }
  }
}
.leave {
  &-design {
    @apply flex items-center justify-center;
  }
  &-employee {
    width: 50% !important;
    td,
    th {
      border: 0 !important;
      background-color: transparent !important;
    }
    td {
      color: #999999;
    }
    th {
      color: #505050;
    }
    .ant-modal-header {
      padding-bottom: 10px;
    }
    .ant-table-content {
      height: 50vh;
    }
  }
}
$priorities: (
  "Sick": #aa347b,
  "Earned": #0ebde0,
  "Casual": #2c5ba7,
  "Permission": #efbf03,
  "On-duty": #d34a7c,
);
.list {
  @each $name, $value in $priorities {
    &-#{$name} {
      background-color: $value;
    }
  }
}
