.tab {
  &-container {
    position: relative;
    box-shadow: 0px 3px 8px #0000000f;
    border-radius: 6px;
    min-height: calc(100vh - 164px);
    padding-left: 20px;
    padding-right: 20px;

    .filters-main {
      // position: absolute;
      // top: -64px;
      // z-index: 1;
      // right: 1rem;
    }

    .ant-table-content {
      padding: 0;
    }

    .common-table {
      height: 100%;
    }
  }
}

.ant-tabs {
  &-nav {
    padding-top: 15px;
  }

  &-tab {
    padding: 14px 0 !important;
    &-btn {
      font-weight: 400;
      font-size: 14px;
      font-family: $rubik-font;
      color: #737373;
    }
    &-btn:hover {
      color: #d34a7c;
    }
    &-active {
      color: $primary-color;
      font-family: $rubik-font;
      font-size: 18px;
      font-weight: 600;
      cursor: auto !important;

      .ant-tabs-tab-btn {
        font-family: $rubik-font;
        font-weight: 500;
        font-size: 14px;
        cursor: auto !important;
      }
    }
  }
}
