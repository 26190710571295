.addButton {
    background: #d23b7d;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    height: 40px;
    font-weight: 500;
    color: #fff;
    font-family: 'Rubik', sans-serif;
    &:active {
        box-shadow:
            0px 1px 10px 5px rgba(0, 0, 0, 0.1),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }
    &:hover {
        opacity: 0.8;
        color: #fff;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &.popup {
        padding: 0;
        height: 40px;
        max-width: 175px;
        width: 100%;
    }

    @media (max-width: 767px) {
        padding: 10px 13px;
        font-size: 12px;
    }

    .plus-Icon {
        margin-right: 15px;
    }

    &.secondary {
        background: #fff;
        box-shadow:
            0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        color: #000;
    }

    &.confirm {
        background: transparent;
        border: 1px solid #d23b7d;
        color: #d23b7d;
        height: 20px;
        padding-top: 2px;
    }

    &.cancel {
        background: transparent;
        border: 1px solid #595959;
        color: #595959;
        height: 20px;
        padding-top: 2px;
    }

    &.cancelElms {
        background: transparent;
        border: 1px solid #acacac;
        color: #d34a7c;
        width: 200px;
        &:hover {
            border: 1px solid #d34a7c;
            color: #000;
        }
    }
    &.cancel-leave {
        background: transparent;
        border: 1px solid #d34a7c;
        color: #d34a7c;
    }

    &.confirmElms {
        border: 1px solid #d23b7d;
        width: 200px;
        color: #fff;
        &:hover {
            border: 1px solid #d34a7c;
            color: #d34a7c;
            background-color: #fff;
        }
    }

    &.basic_plan {
        background-color: transparent;
        color: #d34b7c;
        border-radius: 45px;
        border: 1px solid #d24b7d;
    }

    &.addemploye {
        background: transparent;
        border: 1px solid #d23b7d;
        color: #d23b7d;
        height: 50px;
    }
    &.deprovision {
        height: 40px;
        color: #fff;
    }
    &._deprovision {
        height: 40px;
        background: #fff;
        border: 1px solid #d23b7d;
        color: #d23b7d;
    }
}

._square {
    border: 1px solid #b6b6b6;
    border-radius: 9px;
    height: 40px;
    max-width: 200px;
    width: 100%;
    text-wrap: nowrap;

    &:hover {
        background-color: #d23b7d;
        border: 1px solid #d23b7d;
        color: #fff !important;
    }
}

.course-button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.chapterButton {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aeaeae;
    border-radius: 4px;
    padding: 5px 10px;
    color: #d23b7d;

    &:hover {
        background-color: #d23b7d;
        border: 1px solid #d23b7d;
        color: #fff !important;
    }
}
