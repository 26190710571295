@import "./assets/styles/main.scss";
@import "./assets/styles/variable";

.login-form {
  .login-forgot-password {
    a {
      color: #2c5ba7;
    }
  }
  .ant-spin-spinning {
    top: 0px !important;
    height: 100% !important;
  }
}
form {
  input {
    width: 100%;
    border-bottom: 1px solid #2c5ba7;
  }
}
.login-left-content {
  .login-img {
    max-width: 600px;
    width: 600px;
  }
  .unique-platform-text {
    color: #8a8da2;
    font-size: 21px;
  }
}

/* Media Query for Responsiveness */
@media (max-width: 768px) {
  .login-box {
    width: 80%;
  }
}

/* Additional Styles for Error Messages, Success Messages, etc. can be added as needed */
