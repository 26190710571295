.addleaveapolicy {
  min-width: 1100px !important;
  width: 100%;

  label {
    color: #878787;
    font-size: 16px;
  }

  input,
  .ant {
    height: 45px;
    border: 1px solid #adadad;
    border-radius: 0;

    &-select {
      height: 45px;

      &-selector {
        border-radius: 0;
        border: 1px solid #adadad !important;
      }
    }

    &-modal-body {
      max-height: 600px;
      height: 100%;
      overflow-y: auto;
      padding-left: 25px;
    }
  }

  p {
    color: #878787;
    font-size: 18px;
    font-family: "Rubik", sans-serif;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.leave {
  &-custom {
    width: 100px;

    &-label {
      margin-right: 39px;

      .ant-select-selector {
        width: 190px !important;
      }
    }
  }

  &-after {
    .ant-select-selector {
      width: 250px !important;
    }
  }

  &-accrual {
    .ant-select-selector {
      width: 190px !important;
    }
  }

  &-count {
    input {
      width: 100px;
    }
  }

  .ant {
    height: 50px;
    border: 1px solid #cacaca;
    border-radius: 0;

    &-select {
      &-selector {
        width: 190px;
      }
    }
  }
}

.restrictionContent {
  background-color: #efefef;
  padding: 30px 60px;

  .leave-types {
    label {
      min-width: 170px;
    }
  }

  span,
  p {
    color: #333333;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .ant {
    &-checkbox {
      &-wrapper {
        span {
          color: #737373;
          font-weight: 400;
        }
      }
    }
  }
}

.active-history {
  padding-right: 1rem !important;

  &-bar {
    padding: 0 !important;
  }

  .ant-select-selector,
  .ant-input,
  .ant-picker {
    // border-radius: 30px !important;
  }
}

.active_ {
  padding-right: 4rem;
}

@media (max-width: 1300px) {
  .active_ {
    padding-right: 1rem !important;
  }
}

.history {
  height: calc(100vh - 135px) !important;
  overflow-y: auto;
}

.clear-filter {
  border: 1px solid #d23b7d;
  border-radius: 30px;
  width: 100px;
  color: #d23b7d;
}
