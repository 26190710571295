.progress {
    &-report {
        font-family: Rubik, sans-serif;
        button {
            &.primary {
                width: 140px;
            }
            padding: 0;
            height: 40px;
            min-width: 100px;
        }
        .ant-table-cell-row-hover {
            background: #ffeef4 !important;
            border-bottom: 1px solid #d34a7c !important;
            border-top: 1px solid #d34a7c !important;
            transition:
                background-color 0.4s,
                border-color 0.4s !important;
            &:nth-child(1) {
                border-left: 1px solid #d34a7c !important;
            }
            &:last-child {
                border-right: 1px solid #d34a7c !important ;
            }
        }
    }
}
.add_table-height {
    .ant-table-content {
        height: calc(60vh) !important;
    }
}
