.image {
    &-upload {
        &-elms {
            .ant {
                &-upload {
                    width: 100% !important;
                    &-select {
                        border: 1px dashed #707070;
                    }
                }
            }
        }
    }
}
