.attenance {
    .ant {
        &-picker {
            border-radius: 50px;
            height: 44px;
        }
        &-tabs {
            &-nav {
                &-wrap {
                    height: 35px;
                }
            }
        }
    }
    &-upload {
        .ant {
            &-upload.ant-upload-select {
                max-width: 100% !important;
                height: 150px !important;
                width: 100% !important;
                border: 1px dashed #333333;
                border-radius: 8px;
            }
            &-upload {
                display: flex;
                align-items: center;
                &-wrapper {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
.bulk_upload {
    .form {
        &-item {
            width: 100%;
        }
    }
    .ant-modal-content {
        width: 650px !important;
    }
    .documentUpload > div:nth-child(2) {
        justify-content: end;
    }
    .chapter-document {
        span {
            display: inline-block;
        }
        p {
            justify-content: start;
        }
    }
    .attenance-upload {
        width: 80%;
        display: flex;
        margin: 0 auto;
    }
    // .ant-upload-select:hover {
    //     height: 120px !important;
    // }
}
