.Sunday,
.Saturday {
  color: #d34a7c;
}
.highlighted {
  &.Saturday,
  &.Sunday {
    td {
      background-color: #f5f5f5 !important;
    }
  }
}
.holiday .ant-table-content {
  height: 54vh;
}
