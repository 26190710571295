.react-calendar {
    border: none !important;
}

.react-calendar__tile {
    height: 44px;
    abbr {
        border-radius: 50%;
        max-width: 33px !important;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.react-calendar__tile:hover {
    background-color: white !important;
    abbr {
        background-color: whitesmoke;
    }
}
.react-calendar__tile--active {
    background-color: transparent !important;
    abbr {
        background-color: #d34a7c !important;
        color: #ffffff !important;
    }
}
.react-calendar__navigation__label {
    background-color: white !important;
    pointer-events: none;
}

.react-calendar__month-view__weekdays__weekday {
    color: #d34a7c;
    font-size: 12px;
    abbr {
        text-decoration: none;
    }
}

.react-calendar__tile--now {
    background-color: white !important;
    abbr {
        background-color: white !important;
        border: 1px solid #d34a7c !important;
        color: black !important;
        border-radius: 50%;
        max-width: 33px !important;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.react-calendar__tile--now.react-calendar__tile--active {
    abbr {
        background-color: #d34a7c !important;
        color: #ffffff !important;
        border-radius: 50%;
        max-width: 33px !important;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.react-calendar__tile:disabled {
    background-color: white !important;
    cursor: not-allowed;
    abbr {
        background-color: whitesmoke !important;
        color: #747474 !important;
    }
}
.react-calendar__tile--active:disabled {
    abbr {
        background-color: #d34a7c !important;
        color: white !important;
    }
}
.react-calendar__navigation__label__labelText {
    color: #000000 !important;
    font-weight: 900 !important;
    font-size: 15px !important;
}

.react-calendar__navigation__arrow {
    color: #cacaca !important;
    font-size: 24px;
}
.custom-calendar {
    pointer-events: none;
}
