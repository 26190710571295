.mail {
  font-family: "Rubik", sans-serif;
  .ant-checkbox-inner {
    border: 2px solid #cfcfcf !important;
  }
  &-item {
    background-color: #f5f5f5;
    padding: 8px 22px;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 7px;
    border: 1px solid transparent;
    transition:
      border 0.3s ease,
      background-color 0.3s ease,
      color 0.3s ease; /* Smooth transition for multiple properties */
  }
  &-item:hover {
    border: 1px solid gray; /* Adds a border on hover */
  }

  &-starred {
    max-width: 60px;
  }
  &-list--content {
    width: 100%;
    .content {
      &.border-right {
        border-right: solid 1px rgba(#707070, 0.5);
      }

      padding-left: 14px;
      padding-right: 20px;
      h5 {
        color: #2f2f2f;
        font-size: 17px;
        font-weight: 500;
        font-family: "Rubik", sans-serif;
      }
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        width: 100%;
        color: #707070;
        font-family: "Rubik", sans-serif;
      }
    }
  }
  &-action--container {
    min-width: 155px;
    max-width: 155px;
    display: flex;
    justify-content: end;
  }
  &-status {
    span {
      background-color: #e3e3e3;
      color: #d34a7c;
      padding: 2px 13px 1px;
      font-size: 13px;
      border-radius: 4px;
      font-family: "Rubik", sans-serif;
    }
    P {
      color: #434343;
      font-size: 14px;
      font-family: "Rubik", sans-serif;
    }
  }
  &-select {
    max-width: 80px;
  }
  &-search {
    z-index: 1;
    &--filter {
      background-color: #fff;
    }
  }
}
.all-announcement--date {
  color: #949494;
  font-size: 14px;
}
.announcement-search--filter {
  background-color: #fff;
}

.star-icon {
  margin: 20px auto;
  width: fit-content;
  font-size: 22px;
  position: relative;
  cursor: pointer;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px black;
  // transition: 0.5s;
  padding-left: 7px;
  padding-right: 7px;
}
.star-icon:hover {
  //   transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1) !important;
  //   background-color: #707070;
  background-color: rgba(32, 33, 36, 0.06);
  border: none;
  box-shadow: none;
  opacity: 1;
  border-radius: 50%;
  // -webkit-transform: scale(1);
  transform: scale(1);
}

.star-icon.filled {
  -webkit-text-fill-color: #ffc400;
  -webkit-text-stroke: 1px #ffc400;
}

.star-icon .icon {
  display: block;
  text-align: center;
}
