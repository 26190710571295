.search {
    position: relative;
    font-size: 14px;
    color: #000000;
    padding: 9px 24px 9px 20px;
    background-color: #f5f5f5;
    border-radius: 30px;
    border: none;
    outline: none;
    &.bg-white {
        background-color: #fff !important;
    }
    &:hover {
        box-shadow: 0 0 0 2px rgba(255, 5, 105, 0.06) !important;
    }
}
.search-filter {
    position: relative;
    min-width: 300px;
    max-width: 300px;
    @media (max-width: 767px) {
        min-width: 100%;
    }
    &.certificate {
        width: 100% !important ;
        max-width: unset;
    }
}
.search-filter .material-symbols-outlined {
    position: absolute;
    right: 10px;
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #595959;
}
.main-container {
    .search-filter .material-symbols-outlined {
        top: 40%;
    }
}
.users-page {
    .ant-table-content {
        .ant-table-cell {
            &:last-child {
                text-align: right;
            }
        }
    }
}
.user-table {
    .ant-table-content {
        height: calc(60vh - 40px);
    }
}
