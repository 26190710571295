.unassignedCourse {
    max-height: 60vh;
    overflow-y: hidden;
    overflow-x: hidden;

    h5 {
        &::after {
            content: '';
            width: 80%;
            height: 2px;
            background-color: #c4c4c4;
            display: inline-block;
            margin-left: 2rem;
        }
        &.titleUnassigned::after {
            width: 0;
        }
    }
    .course-status {
        display: flex;
        align-items: center;
        top: 10px;
        right: 10px;
        width: 85px;
        height: 25px;
        border-radius: 4px;
        color: #fff;
        opacity: 0.75;
        display: flex;
        justify-content: center;
    }
}
.slick {
    &-list {
        margin: 15px;
    }
    &-track {
        display: flex;
    }
    &-slider {
        position: relative;
        .slick-prev {
            position: absolute;
            top: 25%;
            left: 7px;
            font-size: 4rem;
            z-index: 6;
            color: #d23b7d;
            cursor: pointer;
            span {
                font-size: 2rem;
            }
        }
        .slick-next {
            position: absolute;
            top: 36%;
            right: -0px;
            color: #d23b7d;
            cursor: pointer;
            span {
                font-size: 2rem;
            }
        }
        .ant-card-body {
            max-height: 170px;
            min-height: 170px;
            height: 100%;
        }
        .ant-card {
            &:hover {
                background-color: #fffafc;
                border: 1px solid #d34a7c;
                transition:
                    background-color 0.4s,
                    border-color 0.4s;
            }
        }
    }
}
@mixin ellipsis($line) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.ant-card-meta-description {
    @include ellipsis(2);
}
.unassigned {
    justify-content: start !important;
    &::after {
        display: none !important;
    }
}
