.leave {
    &-request {
        $priorities: (
            'Approved': #1ad60e,
            'Declined': #ff1e1e,
            'Pending': #f8bc17,
        );
        $prioritie: (
            'Completed': #1ad60e,
            'Retaken': #ff1e1e,
            'In': #f8bc17,
            'Yet': blue,
        );
        $download: (
            'Not': #d34a7c,
            'Downloaded': #909090,
        );

        @each $name, $value in $priorities {
            &-#{$name} {
                width: 10px;
                display: inline-block;
                height: 10px;
                background-color: $value;
                border-radius: 5px;
            }
        }
        @each $name, $value in $prioritie {
            &-#{$name} {
                width: 10px;
                display: inline-block;
                height: 10px;
                background-color: $value;
                border-radius: 5px;
            }
        }
        @each $name, $value in $download {
            &-#{$name} {
                width: 10px;
                display: inline-block;
                height: 10px;
                background-color: $value;
                border-radius: 5px;
            }
        }
    }
    &-type {
        $priorities: (
            'Sick': #cff6ff,
            'Earned ': #e2d3f5,
            'Casual': #fae9c6,
            'Absent': #facfc6,
        );
        $color: (
            'Sick': #0e96b0,
            'Earned ': #8b25ff,
            'Casual': #a16f0b,
            'Absent': #a11a0b,
        );
        @each $name, $value in $priorities {
            &-#{$name} {
                background-color: $value;
            }
        }
        @each $name, $value in $color {
            &-#{$name} {
                span {
                    color: $value;
                    font-size: 1rem;
                    font-weight: 600;
                }
            }
        }
    }
}
