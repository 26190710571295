.employee-stepper {
    width: 100%;
    // padding-top: 10px;
    padding-right: 2%;
    // height: 0px;
    // opacity: 0;
    // visibility: hidden;
    transition: all 0.2s ease;
    // &.show {
    //   opacity: 1;
    //   visibility: visible;
    //   height: 124px;
    // }
    .stepper-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff2f8;
        padding: 0.5rem 1rem;
        margin: 10px 0;
    }
    p {
        font-size: 12px;
        width: 15rem;
        font-weight: 300;
        margin-bottom: 0;
        &.step-title {
            color: $secondary-color;
            font-weight: 500;
            width: 11rem;
        }
    }
    .mail-sent {
        color: #0d620d;
    }
    button {
        font-weight: 400;
    }
    .stepper {
        display: flex;
        align-items: center;
        .step {
            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                width: 50%;
                height: 1px;
                background-color: #c4c4c4;
                top: 8px;
            }
            &:after {
                content: '';
                position: absolute;
                right: 0;
                width: 50%;
                height: 1px;
                background-color: #c4c4c4;
                top: 8px;
            }
            &:nth-child(1) {
                &:before {
                    display: none;
                }
            }
            &:nth-last-child(1) {
                &:after {
                    display: none;
                }
            }
            .active {
                background-color: #2f922b !important;
            }
            span {
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                color: #ffffff;
                background-color: #fff2f8;
                z-index: 1;
                font-family: none;
                text-indent: -9999em;
                border: 2px solid #d34a7c;
            }
            p {
                display: block;
                padding: 0px 20px;
                font-size: 12px;
                margin-top: 5px;
                color: $secondary-color;
                text-align: center;
                font-family: $rubik-font;
            }
            .inactive {
                color: #c4c4c4 !important;
            }
            &.done {
                span {
                    font-family: Rubik, sans-serif;
                    text-indent: inherit;
                    background-color: #d34a7c;
                }
            }
        }
    }
}
.childAction {
    .options {
        color: rgba($color: #595959, $alpha: 0.8);
        font-family: $rubik-font;
        &.active {
            color: $primary-color;
        }
    }
}
