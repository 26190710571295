.salary {
    &_add-btn {
        right: 0;
    }
    &_prticulars {
        &-container {
            ul {
                li {
                    color: #333333;
                    margin-bottom: 20px;
                }
            }

            ._header {
                color: #d34a7c;
                font-weight: 500;
            }

            ._monthly,
            ._annual {
                color: #595959;
                font-weight: 500;
                margin-bottom: 20px;
            }

            .salary-gross {
                color: #333333;
                font-weight: 700;
            }
            .salary-gross-add {
                padding-top: 20px;
            }

            input {
                background: transparent;
                background-color: rgba(0, 0, 0, 0.04);
                border-radius: 4px 4px 0 0;
                padding: 0.75em 0.75em 0 0.75em;
                /* height: 50px; */
                width: 150px;
            }

            .float-label {
                border-bottom: 0;
            }
        }

        &-list {
            max-height: 60vh;
            height: 100%;
        }
    }

    // &-particular {
    //     &-header {
    //     }
    // }

    &-modal {
        max-width: 1020px !important;
        width: 100% !important;
    }
}
.employee-assets {
    max-height: 60vh;
    min-height: 55vh;
    height: 100%;
}

@media only screen and (max-width: 460px) {
    .salary-gross-add {
        padding-top: 0 !important;
    }
}
