.radio-container {
    label {
        color: #333333;
        font-size: 17px;
        font-family: 'Rubik', sans-serif;
    }
}
.bulk-question {
    .addcourse-field {
        #description {
            height: 60px;
        }
    }
    select {
        background-color: #f5f5f5 !important;
        outline: none;
        width: 250px !important;
    }
    &-container {
        margin: 0 auto;
        width: 87%;
    }
}
.upload-images {
    .ant-upload {
        height: 100px !important;
        width: 100px !important;
    }
}

.exit-thankYou
{
      font-family: "Rubik", sans-serif;
      .ant-modal-close
      {
        display: none !important;
      }
}