.toggle {
    &-content {
        margin: auto 0;
    }
    &__icon {
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
}
