.ant-modal {
  &-content {
    border-radius: 4px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -3px 0px 6px #00000029 !important;
    .btn-group {
      display: flex;
      position: absolute;
      top: 30px;
      right: -53px;
      flex-direction: column;
      .material-symbols-outlined {
        color: #ffffff;
        font-size: 27px;
      }
    }
  }
  &-header {
    text-align: left;
    border-bottom: 1px solid #00000029 !important;
    padding: 6px 0;
  }
  &-title {
    position: relative;
    display: inline;
    font-size: 20px !important;
    font-weight: 400 !important;
    color: $primary-color !important;
    font-family: $rubik-font;
    margin-bottom: 20px;
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      // background-color: $primary-color;
      position: absolute;
      bottom: -5px;
      left: 0;
    }
  }
}
.modal {
  &-role {
    width: 820px !important;
    .ant-modal-footer {
      display: none;

      .ant-table-cell {
        background-color: #ffffff !important;
        color: rgba($color: #333333, $alpha: 0.8) !important;
        font-family: $rubik-font;
        font-size: 14px;
        font-weight: 500 !important;
        &::before {
          display: none;
        }
      }
    }
  }
  &-uploadDocument {
    max-width: 792px;
    width: 100% !important;

    .ant-upload-list-item-container {
      width: 338px !important;
      height: 140px !important;
    }

    .form-item.select-width,
    .ant-upload-list-item-container {
      max-width: 338px;
    }

    .ant-upload-select {
      width: 338px !important;
      height: 140px !important;

      .ant-upload,
      .ant-upload div {
        display: flex;
        justify-content: center;
        width: 100% !important;
      }
    }
    .ant-upload-select:hover {
      border-color: #d34a7c;
    }
  }
  &-documentPreview {
    .documentPreview {
      min-height: calc(100vh - 150px);
    }

    max-width: 782px !important;
    width: 100% !important;

    .ant-modal-header {
      display: none;
    }

    .ant-modal-close {
      top: 0;
      right: -50px;
      color: #ffffff;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .file_download {
      position: absolute;
      top: 40px;
      right: -57px;
      font-size: 36px;
      color: #ffffff;
      font-weight: 300;
    }

    .btn-modal__download {
      color: rgba(0, 0, 0, 0.88);
      background-color: rgba(0, 0, 0, 0.06);

      &:hover {
        .file_download {
          color: rgba(0, 0, 0, 0.88);
        }
      }
    }
  }
}

.otherPeripherals {
  min-height: 44px;
  .ant-select-selector {
    min-height: 44px;
  }
}

// .ant-select-selector:hover {
//   border: 1px solid #d34a7c !important;
// }
// .ant-select-selector:focus-visible {
//   outline: none;
// }
