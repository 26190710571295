@import "./BirthDayAnniversary/BirthDayAnniversary.scss";
@import "./EmployeeAttendance/EmployeeAttendance.scss";
@import "./EmployeeChart/employeechart";
@import "./AddEmployeeModal/AddEmployeeModal";
.card {
  background: #ffffff;
  box-shadow: 0px 3px 8px #0000000f;
  border-radius: 6px;
  padding: 35px 29px;
  &.modal {
    box-shadow: 0px 1px 6px #00000029;
    padding: 20px 30px;
  }
}

.dashboard {
  &-container {
    background: #f5f6ff;
    // height: calc(100vh - 70px);
    // overflow-y: auto;
    // padding-bottom: 23rem;
    .bulk-upload_dropdown {
      display: block !important;
    }
    &__left {
      position: relative;
      &.expanded {
        width: 100%;
        .employee-table {
          transition: 0.5s;
          height: 100%;
          width: 100%;
          overflow: hidden;
        }

        .dashboard_chart {
          display: none;
        }
      }
      &.expand {
        width: 71%;
        max-height: 1150px;
        min-height: 1150px;
        .employee-table {
          .ant-table {
            thead {
              th {
                &:nth-child(3) {
                  max-width: 200px;
                  min-width: 200px;
                }
                &:nth-child(4) {
                  max-width: 130px;
                  min-width: 130px;
                }
                &:nth-child(5) {
                  max-width: 130px;
                  min-width: 130px;
                }
                &:nth-child(6) {
                  max-width: 150px;
                  min-width: 150px;
                }
                &:nth-child(7) {
                  max-width: 200px;
                  min-width: 200px;
                }
                &:nth-child(8) {
                  max-width: 200px;
                  min-width: 200px;
                }
                &:nth-child(9) {
                  max-width: 140px;
                  min-width: 140px;
                }
              }
            }
            tbody {
              tr {
                td {
                  &:nth-child(3) {
                    max-width: 200px;
                    min-width: 200px;
                  }
                  &:nth-child(4) {
                    max-width: 130px;
                    min-width: 130px;
                  }
                  &:nth-child(5) {
                    max-width: 130px;
                    min-width: 130px;
                  }
                  &:nth-child(6) {
                    max-width: 181px;
                    min-width: 181px;
                  }
                  &:nth-child(7) {
                    max-width: 228px;
                    min-width: 228px;
                  }
                  &:nth-child(8) {
                    max-width: 232px;
                    min-width: 232px;
                  }
                  &:nth-child(9) {
                    max-width: 140px;
                    min-width: 140px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__right {
      width: 30%;
      padding-left: 1rem;
      max-height: 1150px;
      min-height: 1150px;
      @media (max-width: 767px) {
        width: 100%;
        padding-left: 0;
      }
      &.expand {
        display: none;
        opacity: 0;
        width: 0;
      }
    }
    h3 {
      font-size: 20px;
      letter-spacing: 0px;
      color: #d34a7c;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
  &_chart {
    // min-height: 280px;
    // max-height: 280px;
    height: 100%;
    height: 26%;
    @media (max-width: 767px) {
      height: 100%;
      max-height: 100%;
    }
  }
  &-height {
    // height: calc(100vh - 140px);
    height: auto;
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.dashboard-expand {
  // min-height: 137vh;
  // height: 100vh;
  // overflow-y: scroll;
  // overflow-x: auto;
}
.dashboard-expanded {
  height: auto;
}

.dashboard-main {
  max-height: 1210px;
  min-height: 1210px;
}

.dashboard-main-expanded {
  height: auto;
  min-height: auto;
}
