.signup {
    &-container {
        .web {
            background-image: url('../../../assets/images/client-onboard/web.png');
            background-position: bottom;
        }
        position: relative;
        height: 100vh;
        background: linear-gradient(145deg, #d33c7d 3.1%, #1c4fa2 100%);
        img {
            z-index: 7;
        }
        label {
            color: #000 !important;
        }
        input {
            border-bottom: 1px solid #00000052;
            outline: 0;
        }
    }
    &-form {
        background-image: url('../../../assets/images/client-onboard/signup-form.png');
        background-repeat: no-repeat;
        z-index: 9;
    }
    &-header {
        top: 80px;
        left: 120px;
    }
    .contact_form {
        position: relative;
    }
}
