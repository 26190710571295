.leave-report-input {
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  transition: all 0.3s;
  .ant-select-arrow {
    margin-top: 0 !important;
  }

  &.add-animation {
    height: auto;
    opacity: 1;
    transition: all 0.4s;
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
    overflow-x: auto;
  }

  .ant-select {
    width: 100%;

    .ant-select-selector {
      border: 1px solid #cacaca;
      border-radius: 30px;
      padding: 19px 15px;

      &:hover {
        border: 1px solid #d34a7c;
      }
    }
  }

  input,
  .ant-picker,
  .ant-select-selection-search-input {
    border-radius: 30px;
    height: 40px !important;
    text-transform: capitalize;
  }
}

.leave {
  &-report {
    &.head {
      .ant-empty-normal {
        margin-left: -11px !important;
      }
      th {
        background-color: transparent !important;
      }
    }
    button {
      height: 35px !important;
    }

    td {
      background-color: #f5f5f5;
      border-bottom: 5px solid #fff !important;
      padding: 7px !important;
    }

    thead {
      th,
      tr {
        border: none !important;
        padding: 16px 6px !important;
      }
    }

    .ant-table-content {
      height: 63vh;
      overflow-y: auto;
    }
  }

  &-management {
    td {
      border-bottom: 3px solid #fff !important;
      background-color: #f5f5f5;
      padding: 10px !important;
      color: #737373;
    }

    th {
      padding-left: 10px !important;
    }

    .ant-table-content {
      height: 61vh;
      overflow-y: auto;
    }
  }
  &-table {
    .ant-table-content {
      height: 55vh;
      overflow-y: auto;
    }
  }
}

.holiday {
  &-management {
    th,
    td {
      background: #fcfcfc;
      border-bottom: 0 !important;
      padding-left: 28px !important;
    }
  }
}

.highlighted-row td {
  background-color: #f5f5f5 !important;
  color: #d23b7d;
}
.user-table {
  &.head {
    th {
      background-color: transparent !important;
    }
  }
}
.filter-section {
  transform: translate(-25%, 0);
  color: #737373;
}

.ManageLeave-Modal {
  .float-label {
    position: relative;
    padding: 1.2rem 0 0;
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100% !important;
}
