.employee {
    &-journey {
        max-height: 70vh;
        height: 100%;
    }
    &-list {
        &-items {
            padding-left: 90px;
            padding-bottom: 30px;
            font-size: 16px;
            font-weight: 600;
            color: #d34a7c;
            height: 100px;
            margin-top: 10px;
            &::after {
                content: '';
                position: absolute;
                bottom: -3px;
                left: 31px;
                width: 2px;
                height: 40px;
                background-color: #c4c4c4;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
        &_single {
            top: -5px;
            left: 0px;
            width: 65px;
            height: 65px;
            background-color: #d34a7c;
            color: #ffffff;
            border-radius: 50%;
            font-size: 35px;
        }
        &-name {
            font-size: 14px;
            color: #595959;
            font-weight: 400;
            line-height: 1.4;
            margin-top: 5px;
            max-width: 50%;
        }
    }
}
