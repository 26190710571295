.profilePicture {
    p {
        background: $border-gradient;
        width: 50px;
        height: 50px;
        margin-bottom: 6px;
        margin-left: 10px;
        font-size: 1.35rem;
    }
    .ant-dropdown-trigger {
        display: block;
    }
}

.logout-dropdown {
    padding: 5px 15px;
    transition: opacity 0.3s ease-in;
    top: 69px;
    right: -2px;
    width: 175px;
    box-shadow: 0px 3px 8px #00000029;
    border: 1px solid #e3e3e3;
    &.visible {
        display: block;
        opacity: 1;
    }
    &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 4px;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        background: $primary-color;
        background: $border-gradient;
    }
}

.logout-item {
    padding: 5px;
    border-bottom: 1px solid $gray;
    p {
        color: $secondary-color;
    }

    &:last-child {
        border-bottom: none;
    }
}
.logout-icon {
    .material-symbols-outlined {
        color: $primary-color;
        font-size: 22px;
    }
}
