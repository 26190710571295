.employee {
    &-appraisal {
        &-container {
            max-height: 66vh;
            height: 100%;
            margin-top: 10px;
        }
        &-card {
            background-color: #f5f5f5;
            border-radius: 4px;
            padding: 1rem;
            margin-bottom: 1rem;
            padding-top: 1rem;
            margin-top: 10px;
            max-height: 70vh;
            height: 100%;
            overflow-y: auto;
        }
        &-title {
            font-size: 0.875rem;
            color: #333333;
        }
    }
    .header {
        &-status {
            color: #878787;
        }
        &-border {
            border-right: 2px solid #878787;
            padding-right: 10px;
        }
        &-edit {
            right: 0px;
            top: 50%;
            transform: translateY(-15%);
            span {
                font-size: 20px;
                padding-left: 10px;
                padding-top: 5px;
            }
        }
    }
}
.close-confirmation-popup {
    .ant-modal-close {
        display: none;
    }
}
