.btn 
{
    &-delete{
        display: flex;
        background-color: #F5F5F5;
        border: #F5F5F5;
        color: rgba($color: #595959, $alpha: .5);
        font-weight: 500;
        font-family: $rubik-font;
    }
}
.ant-btn-primary {
    background-color: $primary-color;
}