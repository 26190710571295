.salaryStructure {
    &_bg {
        box-shadow:
            0 1px 1px #1f21240d,
            0 2px 3px #1f21241a;
        border-radius: 10px;
    }

    &_list {
        li {
            margin-top: 30px;
        }
    }

    &_value {
        li {
            margin-top: 16px;
        }
    }
}

.salary-value {
    background: #f5f5f5;
    border-radius: 2px;
    width: 68px;
    text-align: center;
}

.salaryStructure-modal {
    width: 596px;

    .edit-salary-details {
        .float-label {
            &:nth-child(1) {
                max-width: 200px;
            }

            &:nth-child(2) {
                width: 256px;
            }
        }
        .ant-select {
            &-arrow {
                top: 75% !important;
            }
            &-dropdown {
                width: 150px !important;
            }
            height: 50px;
            &-selection-item {
                padding-top: 10px;
            }
            &-selector {
                margin-top: 10px;
                background-color: rgba(0, 0, 0, 0.04);
                border-radius: 4px 4px 0 0;
                border-right: none;
                border-left: none;
                border-top: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.42);
            }
        }
    }
}
