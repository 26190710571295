.sidebar {
    &-container {
        position: fixed;
        height: 100%;
        left: 0;
        top: 0;
        display: block;
        z-index: 9;
        transition: 0.3s ease;
        &__toggle {
            width: 300px;
            height: 100%;
            top: 70px;
            padding-top: 37px;
            background-color: #ffffff;
            box-shadow: 6px 0px 6px #3333330f;
            border-radius: 0px 6px 6px 0px;
            left: 0px;
            transition: 0.3s ease;
            position: fixed;
            z-index: 9;
            &::before {
                content: '';
                position: absolute;
                height: 100%;
                width: 6px;
                right: 0;
                top: 0;
                border-radius: 3px 6px 0 3px;
                background: linear-gradient(3deg, #a8428a, #5252a8);
            }
            &__active {
                position: absolute;
                transition: 0.3s ease;
                width: 260px;
                height: 100%;
                top: 70px;
                left: -260px;
                padding-top: 25px;
                background-color: #ffffff;
                box-shadow: 6px 0px 6px #3333330f;
                border-radius: 0px 6px 6px 0px;
            }
        }

        &__menu {
            li {
                display: block;
                cursor: pointer;
                padding: 15px 30px;
                transition: 0.3s ease;
                &:hover {
                    background-color: #ffeff6;
                    color: #d34a7c;
                    img {
                        filter: brightness(0) saturate(100%) invert(31%) sepia(72%) saturate(1978%) hue-rotate(310deg) brightness(88%) contrast(85%);
                    }
                }
            }
            li:nth-child(10) {
                display: block;
                cursor: pointer;
                padding: 15px 30px;
                .icon {
                    width: 24px;
                }
                img {
                    width: 100%;
                }
                span {
                    margin-left: 18px;
                }
            }
            li:nth-child(8) {
                display: block;
                cursor: pointer;
                padding: 15px 30px;
                .icon {
                    width: 22px;
                }
                img {
                    width: 100%;
                }
                span {
                    margin-left: 19px;
                }
            }
            li:nth-child(6) {
                display: block;
                cursor: pointer;
                padding: 15px 30px;
                .icon {
                    width: 22px;
                }
                img {
                    width: 100%;
                }
                span {
                    margin-left: 19px;
                }
            }
        }
    }
}
.active-breadcrumb {
    color: #ff4081 !important;
}
