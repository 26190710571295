.basicForm-page {
  background: #dfe1f1;
  height: 100%;
  .basic-info-start {
    border-radius: 24px;
  }
  h2,
  h3,
  p {
    color: #1f3c66;
  }
  .error-message {
    color: red;
    margin-top: 23px;
    font-weight: 300;
    font-size: 14px;
  }
  .ant-spin-spinning {
    top: 0px;
    left: 0;
    height: 100%;
  }
}
.basicForm-fields {
  .float {
    &-label {
      border-bottom: 0;
    }
    &-control {
      background-color: #fafafa;
      border-radius: 4px 4px 0 0;
      padding: 0.75em 0.75em 0 0.75em;
      height: 50px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }
  }
  input {
    padding-left: 10px !important;
  }
  label {
    padding-left: 10px;
    padding-top: 15px;
  }
  .text-red-500 {
    display: none;
  }
  .ant-select-selector {
    background-color: transparent !important;
  }
}
.form-submit-btn {
  background: $primary-color;
  border-radius: 13px;
  padding: 13px 40px;
  background-color: #d23b7d;
  font-size: 18px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.privacyPolicy {
  margin-top: 2rem;
  h2 {
    font-size: 1.5rem;
    padding: 1rem 0;
    font-weight: 600;
  }
  p {
    color: #fff;
    line-height: 1.8;
  }
  &-container {
    ul li {
      color: #fff;
      line-height: 1.8;
      list-style: inside;
    }
  }
}
