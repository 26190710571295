.add-certificate-details {
  .ant-select-selection-item {
    color: #c3c3c3;
    font-size: 14px;
  }
}
.course-tab-containers {
  .ant-tabs-tab {
    cursor: default;
  }
}
