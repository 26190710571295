.organisation_edit-form {
    height: 75vh;
    overflow-y: scroll;
}

.file-upload {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: block;
    margin: 20px 0;

    .file-upload-inner {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border: 1px dashed #333333;

        input {
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            cursor: pointer;
            // border-radius: 50%;
            z-index: 2;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
            max-height: 100%;
        }
    }

    .material-symbols-outlined {
        font-size: 50px;
        color: #9f9f9f;

        &.edit {
            right: 0px;
            top: -5px;
            font-size: 20px;
            padding: 7px;
            color: #ffffff;
            border-radius: 20px;
            background-color: #e672ac;
            box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.16);
            cursor: pointer;
        }
    }
}
.sign-file-upload {
    min-width: 200px;
    height: 60px;
    border-radius: 10px;
    display: block;
    margin: 20px auto;

    .sign-file-upload-inner {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border: 1px dashed #333333;

        input {
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            cursor: pointer;
            border-radius: 50%;
            z-index: 2;
        }

        .ff {
            width: 100%;
            height: auto;
            object-fit: contain;
            max-height: 100%;
            // padding-left: 50px;
        }
        .sign-uploaded {
            width: 100%;
            height: 100%;
        }
    }

    .material-symbols-outlined {
        &.edit {
            font-size: 20px;
            padding: 7px;
            height: 100%;
            color: #ffffff;
            background-color: #e672ac;
            box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.16);
            cursor: pointer;
        }
        &.cloud {
            padding-left: 50px;
            font-size: 50px;
            color: #9f9f9f;
        }
    }
}
.organisation-btn {
    &:hover {
        background-color: #d23b7d;
        color: #fff;
    }
}
