.breadcrumb {
    display: flex;
    align-items: center;

    li {
        padding: 0 0.7rem 0 0;
        display: flex;
        position: relative;
        font-family: 'Rubik', sans-serif;

        &.active {
            cursor: pointer;
            color: #ff4081;
            font-weight: 400;

            &:hover,
            &:active {
                // color: lighten(#ff4081, 10%);
                color: #878787;
            }
        }

        text-decoration: none;
        color: #878787;
        cursor: pointer;

        &:hover,
        &:active {
            color: lighten(#ff4081, 10%);
        }
    }

    &.breadcrumb--classic {
        li {
            &:not(:last-of-type) {
                &::before {
                    content: '/';
                    display: block;
                    position: absolute;
                    font-size: 1rem;
                    top: 50%;
                    right: 0;
                    transform: translate(50%, -50%);
                    color: darken(#c9d1d9, 30%);
                }
            }
            &:nth-child(2) {
                padding-left: 10px;
            }
            &:nth-child(3) {
                padding-left: 10px;
            }
            &:nth-child(4) {
                padding-left: 10px;
            }
        }
    }
}
