.client {
    &-business {
        &-plan {
            .btn-container {
                label {
                    font-size: 13px;
                    color: #d2daf6;
                    font-weight: 500;
                }
                .btn-color {
                    &-mode-switch {
                        display: inline-block;
                        margin: 0px;
                        & > label.btn-color-mode-switch-inner {
                            margin: 0px;
                            width: 400px;
                            height: 50px;
                            background: #d2daf6;
                            border-radius: 26px;
                            overflow: hidden;
                            position: relative;
                            transition: all 0.3s ease;
                            display: block;
                            &:before {
                                content: 'Yearly';
                                position: absolute;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 18px;
                                font-weight: 500;
                                top: 25%;
                                right: 20%;
                                color: #6a728e;
                            }
                            &:after {
                                content: 'Monthly';
                                width: 50%;
                                height: 90%;
                                background: #d34a7c;
                                color: #fff;
                                border-radius: 26px;
                                position: absolute;
                                left: 2px;
                                top: 2px;
                                text-align: center;
                                transition: all 0.3s ease;
                                box-shadow: 0px 0px 6px -2px #111;
                                padding: 5px 0px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 18px;
                            }
                        }
                        input[type='checkbox'] {
                            cursor: pointer;
                            width: 50px;
                            height: 25px;
                            opacity: 0;
                            position: absolute;
                            top: 0;
                            z-index: 1;
                            margin: 0px;
                            &:checked + label.btn-color-mode-switch-inner {
                                background: #d2daf6;
                                color: #6a728e;
                                content: 'Monthly';
                            }
                            &:checked + label.btn-color-mode-switch-inner:after {
                                content: 'Yearly';
                                left: 50%;
                                background: #d34a7c;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
            background-image: url('../../../assets/images/client-onboard/business-plan-bg.png');
            .plan {
                &-cards {
                    &:hover {
                        transform: scale(1.1);
                        -webkit-transition: all 200ms ease-in;
                        -webkit-transform: scale(1.1);
                        -ms-transition: all 200ms ease-in;
                        -ms-transform: scale(1.1);
                        -moz-transition: all 200ms ease-in;
                        -moz-transform: scale(1.1);
                        transition: all 200ms ease-in;
                    }
                    .basic-plan {
                        filter: drop-shadow(0px 0px 4.908px rgba(0, 0, 0, 0.14));
                        border-radius: 10px;
                        .plan-popular {
                            top: -20px;
                            left: 50%;
                            transform: translateX(-50%);
                            background: #0932c5;
                            padding: 5px 25px;
                            border-radius: 20px;
                            color: #fefefe;
                            font-size: 16px;
                            font-weight: 500;
                            height: 40px;
                            width: 130px;
                        }
                    }
                    &.popular-plan {
                        .basic-plan {
                            box-shadow: 0px 4.908px 52.02px 0px rgba(238, 114, 161, 0.5);
                        }
                    }
                }
                &-description {
                    ul > li {
                        color: #464646;
                        font-family: Rubik;
                        font-size: 17.667px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    &-container {
        .contact_form {
            position: relative;
        }
    }
}
.basic {
    &-price {
        sub {
            bottom: -2.9em !important;
        }
    }
    &-plan {
        max-height: 800px;
        height: 100%;
    }
}
.payment {
    .card {
        max-height: 550px;
    }
    &-description {
        .business {
            font-size: 20px;
            font-weight: 500;
            color: #585858;
            display: flex;
            align-items: center;
        }
        .amount {
            display: flex;
            justify-content: space-between;
            margin: 0 20px 0 35px;
            border-bottom: 1px solid #3c42571f;
            p {
                font-size: 20px;
                font-weight: 500;
                color: #585858;
                padding: 10px 0;
                &.font {
                    font-weight: 700;
                }
            }
            span {
                font-size: 20px;
                font-weight: 500;
                color: #585858;
                display: flex;
                align-items: center;
                &.text {
                    color: #d35080;
                    font-weight: 600;
                }
            }
        }
    }
}
