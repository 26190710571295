@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

$rubik-font: "Rubik", sans-serif;
.asset {
  &-collection {
    .ant {
      &-table-thead {
        th {
          padding: 5px 0 !important;
          background-color: #fff !important;
          color: #464646;
        }
        th:last-child {
          padding: 5px 8px !important; /* Adjust padding as needed */
        }
      }
      &-table-tbody {
        td {
          padding: 5px 0 !important;
          color: #595959;
          font-family: $rubik-font;
        }
      }
      &-table-content {
        max-height: 300px;
      }
    }
  }
}
._status {
  .ant-select-selector {
    border: 0 !important;
  }
}
.hilighted {
  .ant-select-selection-item {
    &[title="Approved"] {
      color: #23a12c !important;
    }
  }
}
.deprovision_requestion {
  width: 1000px !important;
  .date_picker {
    border-left: 1px solid #cbcbcb;
    box-shadow: -1px 0px 0px #00000029;
    background: #ffffff 0% 0% no-repeat padding-box;
  }
  .ant-modal-header {
    padding-bottom: 15px !important;
  }
}

.__select {
  .ant-select-selector {
    border: 0 !important;
    box-shadow: none !important;
  }
}
.__select-kt {
  .ant-select-selector {
    border: 0 !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #cbcbcb !important;
    box-shadow: none !important;
    padding-left: 0 !important;
  }
}

.deprovision_visiblity_parentNot {
  pointer-events: none;
  .deprovision_visiblityNot {
    color: rgba(0, 0, 0, 0.25);
  }
}
.Email_Deprovision {
  .ant-spin-spinning {
    top: 0px;
    left: 0;
    height: 100%;
  }
}
