.organization {
  background-color: #f5f6ff;
  height: auto;
  &__Tab {
    border-radius: 6px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.0588235294);
    min-height: calc(100vh - 164px);
    padding-left: 20px;
    padding-right: 20px;
  }
  .ant-tabs-nav {
    padding: 10px 21px 0px 21px;
  }
  &_title {
    font-size: 22px;
  }
  &_back-arrow {
    color: rgba(0, 0, 0, 0.26);
  }
}
