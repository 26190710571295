.approved-screen {
  height: 100vh;
  background: url(../../images/approved_screen_banner.png) no-repeat center;
  background-size: cover;
  .header__logo {
    padding: 2rem 3rem;
    height: auto;
    span {
      padding: 0;
    }
  }
  &.exitSuccess {
    h2 {
      font-size: 2rem;
      font-weight: 500;
      font-family: "Rubik", sans-serif !important;
    }
    p {
      font-size: 1.2rem !important;
      margin: 0 0 0rem !important;
      font-family: "Rubik", sans-serif !important;
    }

    .card {
      padding: 1rem;
    }
    .material-symbols-outlined {
      font-size: 5rem !important;
    }
  }
  .card {
    padding: 3rem;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);
    max-width: 90%;
    width: 600px;
    margin: auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);

    .material-symbols-outlined {
      font-size: 8rem;
      color: #549f47;
    }
    p {
      font-size: 1.5rem;
      line-height: 1.5;
      margin: 0 0 1.5rem;
    }
  }
  .ant-spin-spinning {
    top: 0px !important;
    height: 100% !important;
  }
}
