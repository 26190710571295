.kpi-bg {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.0588235294);
    border-radius: 6px;
}
.kpi-calender {
    min-height: calc(100vh - 227px);
    width: 100%;
    overflow-x: auto;
    &_grid {
        grid-template-columns: repeat(32, 100px);
        min-height: 254vh;
        p {
            border-right: 1px solid #e3e3e3;
        }
    }
    &_data {
        padding: 40px 0 0;
        ul {
            display: grid;
            grid-template-columns: repeat(32, 100px);
            li {
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
                border-radius: 8px;
                padding: 0.5rem;
                z-index: 0;
                &:nth-child(1) {
                    background-color: rgb(211, 74, 124);
                    grid-column: 3 / 7;
                }
                &:nth-child(2) {
                    margin: 40px 0 0;
                    background-color: rgb(44, 91, 167);
                    grid-column: 4 / 20;
                }
                &:nth-child(3) {
                    margin: 40px 0 0;
                    background-color: rgb(44, 91, 167);
                    grid-column: 2 / 5;
                }
            }
        }
    }
}
