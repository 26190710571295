.asset {
    &_modify {
        border-bottom: 1px solid #e3e3e3;
        padding-bottom: 15px;

        // margin: 0 15px;
        .ant-radio {
            &-wrapper {
                width: 100px;
            }
        }
    }

    &-container {
        .ant {
            &-collapse-content {
                background-color: #f5f5f5 !important;
            }

            &-radio {
                &-wrapper {
                    width: 100px;
                }
            }
        }

        padding: 10px 0;

        // background-color: #f5f5f5;
        .ant-collapse {
            &-expand-icon {
                span {
                    background: rgb(255, 255, 255);
                    border-radius: 50%;
                    padding: 10px;
                }
                display: flex;
                align-items: center;
                justify-content: center;
                height: 68px !important;
            }

            &-header {
                background-color: #f5f5f5;

                &-text {
                    padding-top: 15px;
                    display: block !important;
                    color: #878787;
                    font-size: 0.75rem;
                    font-family: 'Rubik', sans-serif;
                    position: relative;
                    font-weight: 400;
                    letter-spacing: 0.12px;
                }
            }
        }

        .header {
            &-assigned,
            &-status {
                border-right: 2px solid #878787;
                padding-right: 10px;
            }
        }
    }
    &-label {
        width: 150px;
        display: inline-block;
    }

    &-specs {
        padding: 10px 0 10px 0;

        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            bottom: -4px;
            border-top: 5px solid #ffffff;
        }
    }
}

.provision_container {
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 10px;

    .ant {
        &-picker,
        &-select-selector,
        &-input {
            background-color: #f5f5f5 !important;
        }
    }
}

.assets {
    &-item {
        margin: 1rem 0 1rem;
        .ant-radio-wrapper {
            width: 120px;
        }
    }

    &-element {
        &__type {
            margin-bottom: 0.2rem;
        }
    }
}
.processor,
.storageType {
    display: none;
}

.processor.active,
.storageType.active {
    display: block !important;
}
