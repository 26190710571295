.documentUpload {
    &.upload {
        .ant-upload-list-item {
            border-color: red !important;
        }
    }
    .ant-tooltip {
        display: none;
    }
}
