//color
$common-white: #ffffff;
$primary-color: #d34a7c;
$secondary-color: #333333;
$darkBlue-color: #1f3c66;
$gray: #ccc;
$border-gradient: transparent linear-gradient(94deg, #4555ad 0%, #c53d81 100%) 0% 0% no-repeat padding-box;

//font family
$rubik-font: 'Rubik', sans-serif;
