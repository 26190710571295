.employee {
    &-course-status {
        height: 65vh;
    }
    &-unlocked {
        border-bottom: 1px solid rgba(71, 71, 71, 0.2);
        &-level {
            height: 35px;
            border-radius: 15px 15px 0 0;
            background-color: #fff0f5;
            min-width: 220px;
            max-width: 250px;
        }
    }
    &-progress {
        .ant {
            &-progress {
                &-text {
                    display: none;
                }
                &-bg {
                    background-color: #d34a7c;
                }
            }
        }
        .listOf {
            &-course {
                // max-height: 66vh;
                height: 100%;
                &-card {
                    border: 1px solid transparent;
                    &:hover {
                        background-color: #fffafc;
                        border: 1px solid #d34a7c;
                        transition:
                            background-color 0.4s,
                            border-color 0.4s;
                    }
                }
            }
        }
        &_stepper {
            height: 65vh;
            border-radius: 10px;
            .ant {
                &-steps {
                    height: 65vh;
                    // overflow-y: auto;
                    &-item {
                        flex: 0.1 0 auto !important;
                        &-finish {
                            .ant-steps-item-icon {
                                background-color: #49bb1f;
                                .ant-steps-icon {
                                    color: #fff;
                                }
                            }
                        }
                        &-wait {
                            .ant-steps-item-icon {
                                background-color: #fff;
                                .ant-steps-icon {
                                    color: #fff;
                                }
                            }
                        }
                        &-icon {
                            border-color: #d6d6d6;
                            background-color: #fff !important;
                        }
                        &-tail:after {
                            border: 1px dashed #707070 !important;
                            background-color: unset !important;
                        }
                    }
                }
            }
        }
        &-card {
            height: 70vh;
            overflow-y: auto;
            &-container {
                border-bottom: 1px solid #c4c4c4;
                .ant-card {
                    &:hover {
                        background-color: #fffafc;
                        border: 1px solid #d34a7c;
                        transition:
                            background-color 0.4s,
                            border-color 0.4s;
                    }
                }
            }
            &-list {
                width: 230px;
                height: 240px;
                margin: 15px 15px 0 0 !important;
                background-color: #ffffff;
                .ant {
                    &-card {
                        &-meta-title {
                            text-align: center;
                            padding-top: 1.5rem;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                        &-cover {
                            height: 165px;
                            img {
                                height: 100%;
                                padding: 10px;
                            }
                        }
                        &-body {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}
.progress {
    &-report {
        .ant-table-cell-row-hover {
            background: #ffeef4 !important;
            border-bottom: 1px solid #d34a7c !important;
            border-top: 1px solid #d34a7c !important;
            transition:
                background-color 0.4s,
                border-color 0.4s !important;
            &:nth-child(1) {
                border-left: 1px solid #d34a7c !important;
            }
            &:last-child {
                border-right: 1px solid #d34a7c !important ;
            }
        }
    }
}
.bg_yet-start {
    .ant-steps-item-icon {
        background-color: #949494 !important;
    }
}
