.edit {
    &-employee {
        .ant-upload-wrapper {
            display: flex;
            justify-content: center;
        }

        &-header {
            color: #ff4081;
            font-size: 18px;
            margin-left: 0px;
            padding-bottom: 20px;
        }

        .update-warning {
            background: #ffeded;
            color: #ff0000;
            padding: 10px;
        }
    }

    &-personal-details {
        .text-red-500 {
            display: none;
        }

        span {
            color: #333333;
            font-size: 14px;
            &.delete {
                color: #fff;
                font-size: 18px;
            }
        }
    }
}

.salary-modal {
    .ant {
        &-tabs {
            &-content {
                padding-right: 15px;

                &-holder {
                    height: calc(80vh - 100px);
                    overflow-y: scroll;
                }
            }
        }
    }
}

.company {
    &-details {
        &-header {
            color: #1f3c66;
            font-size: 16px;
        }
    }
}
.add-employee-modal {
    .upload-document-image {
        span {
            font-size: 10px !important;
        }
    }
}
