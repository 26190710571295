@mixin ellipsis($line) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
.courseDetailsTop-bg {
    width: 100%; /* Set a specific width */
    height: 0; /* Set a specific height, e.g., 56.25% (16:9 aspect ratio) of the width */
    padding-bottom: 17.25%; /* 16:9 aspect ratio */
    position: relative;
    .img {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/new-dashboard/CourseDetailsImg.png');
        background-repeat: no-repeat;
        background-size: contain;
    }
    .ant-table-pagination {
        display: none !important;
    }
}
.course-description {
    @include ellipsis(3);
}
.courseDetails-chapter,
.courseDetails-cc,
.courseDetails-fa {
    background: #ffffff;
    box-shadow: 0px 3px 8px #0000000f;
    border-radius: 6px;
    .ant-table {
        th,
        tr {
            background-color: #f5f5f5;
            border: none;
            td {
                border: none;
                &:nth-child(2) {
                    color: #d34a7c;
                }
            }
            th {
                background-color: #f5f5f5;
                border: none;
            }
        }
    }
}

.courseDetails-cc,
.courseDetails-fa {
    height: 47%;
    .border-dashed {
        height: 79%;
    }
}
.description_ {
    width: 800px !important;
}
.course_table {
    .course-table {
        background-color: #fff;
        padding-left: 1.25rem;
        padding-right: 1.25rem;

        .ant-table-content {
            max-height: 470px;
            overflow-y: auto;
        }
    }
}
.dashboard-table{
    .course-table{
        ._dashboard{
height: 470px;

        }
        .ant-table-content {
            height: 470px;
            overflow-y: auto;
        }
 }
}
.edit-responsive{
    max-height: 425px !important;
    min-height: 425px !important;

}