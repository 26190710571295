.document {
    &-view {
        background: #f7f6f8;
        margin-right: 25px;
        padding: 15px;
        margin-bottom: 8px;
        border-radius: 8px;
    }
    &-fileName {
        color: #595959;
        max-width: 430px;
        word-break: break-word;
    }
    &-action {
        &__icon {
            span {
                width: 30px;
                height: 30px;
                margin: 0px 7px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 19px;
                background: transparent;
                color: #d34a7c;
                border: 1px solid #d34a7c;
                border-radius: 30px;
                cursor: pointer;
                transition: all 0.4s ease;
            }
        }
    }
    &-add {
        button {
            padding: 0 !important;
            cursor: pointer;
            &:hover {
                background-color: transparent !important;
            }
            span {
                cursor: pointer;
            }
        }
    }
}
.modal-uploadDocument {
    width: 530px !important;
    .ant-upload-select {
        width: 100% !important;
    }
    .upload-document-image {
        .material-symbols-outlined {
            font-size: 50px !important;
            color: #3f2093;
        }
        span {
            color: #3f2093;
            font-size: 16px;
        }
    }
}
