.addcourse {
  width: 1200px !important;

  &-stepper {
    padding: 20px 0 20px 0;
    margin: 0 auto;
  }

  &-main {
    margin: 0 auto;
  }

  &-container {
    margin: 0 auto;
  }

  &-field {
    input {
      height: 50px;
      background: #f5f5f5;
      padding: 10px 20px !important;
      border-bottom: 0;
      width: 100%;
      border-radius: 5px !important;
      outline: none;
    }
    input:hover {
      box-shadow: 0 0 0 2px rgba(255, 5, 105, 0.06);
    }

    #description {
      height: 150px;
      background: #f5f5f5;
      padding: 10px 20px;
      border-bottom: 0;
      outline: none;
      width: 100%;
      border-radius: 5px;
      resize: none !important;
    }
    #description:hover {
      box-shadow: 0 0 0 2px rgba(255, 5, 105, 0.06);
    }
  }

  &-lable {
    color: #737373;
    font-size: 16px;
  }

  .ant {
    &-modal {
      &-content {
        padding-right: 30px;
        padding-left: 40px;
        // min-height: 642px;
        // max-height: 642px;
        min-height: 654px;
        max-height: 654px;
        height: 100%;
      }
    }

    &-upload {
      max-width: 447px !important;
      height: 254px !important;
      width: 100% !important;
      &.ant-upload-select {
        border: 2px dashed #707070 !important;
        border-radius: 8px;
      }

      &-list {
        &-item {
          &-container {
            width: 350px !important;
            height: 155px !important;
            border-color: #707070;
          }
        }
      }
    }
  }

  .chapter {
    &-upload-bulk {
      &.question {
        min-height: 407px;
        padding: 1rem 0;

        .ant-select {
          width: 100%;
          height: 50px;

          &-selector {
            background-color: #f5f5f5;
          }
        }
      }

      margin: 0 auto;

      select {
        background-color: #f5f5f5 !important;
      }
    }

    &-title {
      color: #313131;
      font-size: 18px;
      font-weight: 600;
    }

    &-lists {
      label {
        font-size: 18px;
        color: #727272;
      }
    }

    &-content {
      width: 40%;
      margin: 0 auto;
    }

    &-url {
      .chapter-field {
        max-width: 60%;
        width: 100%;
      }

      p {
        color: #737373;
      }
    }

    &-field {
      input,
      select {
        background: #f5f5f5 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: 0;
        height: 40px;
        padding: 0 10px;
        width: 100%;
        outline: none;
      }
    }

    &-document {
      .ant-upload {
        min-width: 250px !important;
        max-width: 275px;
        height: 154px !important;
        width: 100% !important;
      }

      &.bulk_upload {
        .ant-upload {
          width: 350px !important;
        }
      }
    }

    &-list {
      &-items {
        width: 45%;

        &.-questions {
          li label {
            color: #d23b7d;
          }
        }
      }

      &-swap {
        width: 10%;

        &-single {
          background: #ffe6f1 0% 0% no-repeat padding-box;
          border-radius: 5px;
          height: 40px;
          width: 40px;
          color: #d34a7c;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px 0 10px 0;
          &:hover {
            background-color: #fff;
          }
        }

        .double {
          background: #d23b7d 0% 0% no-repeat padding-box;
          border-radius: 5px;
          height: 40px;
          width: 40px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px 0 10px 0;
          &:hover {
            background-color: #fff;
            color: #d34a7c;
          }
        }
      }

      &-item {
        width: 45%;
      }
    }

    &-items {
      min-height: 250px;
      max-height: 340px;
      height: 100%;

      p {
        color: #d23b7d;
        font-size: 18px;
        padding: 10px 0;
      }

      li {
        padding: 10px 0;

        .ant {
          &-radio {
            &-wrapper {
              span {
                color: #727272;
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    &-certificate {
      max-width: 250px;
      height: 170px;
      width: 100%;
      border-radius: 8px;

      &-preview {
        border-radius: 8px;
      }
    }

    &-assesment {
      &-count {
        width: 115px;
        height: 45px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 5px;

        .ant-input {
          height: 45px;
        }

        p {
          color: #2c2c2c;
          font-weight: 700;
        }
      }
    }
  }

  .main {
    &-container {
      .ant-table-placeholder {
        display: none;
      }
      .common-table {
        height: 45vh;
      }

      .chapter {
        &-assign {
          overflow-y: auto;
          background-color: #f5f5f5;

          &-customize {
            min-height: 470px;
          }
        }
      }

      &.assesment {
        max-height: calc(100% - 140px);
        height: 100%;
      }

      .ant {
        &-tabs {
          height: 100%;

          &-content {
            height: 100%;
          }

          &-tabpane {
            height: 435px;
          }
        }
      }

      max-height: calc(91% - 33px);
      height: 100%;

      .ant-table-content {
        height: 435px;
      }
      &.final-assessment {
        min-height: 100%;

        .chapter {
          &-criteria {
            background-color: #f5f5f5;
          }

          &-items {
            min-height: 300px;
          }
        }
      }

      .footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;

        &-customize {
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }
    }
  }

  .ant-tabs-nav-wrap {
    background: #fff;
  }

  &-upload {
    overflow: hidden;
    border: 1px dashed #333333;
    padding: 20px;
  }
}

.course {
  &-table {
    table,
    td,
    tr,
    th {
      border: 0 !important;
      background-color: #f5f5f5 !important;
      .ant-table-cell {
        background-color: #f5f5f5;
      }
    }
    .ant-table-placeholder {
      td {
        background-color: #fff !important;
      }
    }
    .common-table {
      background-color: #f5f5f5;
      border-radius: 3px;
      min-height: 400px !important;

      thead th {
        background-color: #f5f5f5 !important;
      }
    }

    .ant-table {
      &-selection-column {
        display: none;
      }

      &-container {
        background-color: #f5f5f5;
      }
    }
  }

  &-tab {
    &-container {
      height: 100%;

      &.questions {
        .chapter-items {
          max-height: 330px;
          height: 100%;
        }
      }

      .ant {
        &-tabs {
          &-ink-bar {
            display: none;
          }

          &-nav {
            margin: 0 !important;
          }

          &-tab {
            width: 200px;
            display: flex;
            justify-content: center;
            padding-bottom: 10px !important;
            margin-left: 0 !important;

            &-active {
              background: #f5f5f5;
              border-radius: 10px 10px 0 0;
            }
          }
        }
      }
    }

    &-containers {
      &.questions {
        .chapter-items {
          max-height: 275px;
          height: 100%;
        }
        .common-footer {
          bottom: -53px;
          padding-left: 0;
          padding-right: 0;
          .confirmElms {
            margin-right: 0;
          }
        }
      }

      .ant {
        &-tabs {
          &-ink-bar {
            display: none;
          }

          &-nav {
            margin: 0 !important;
          }

          &-tab {
            width: 200px;
            display: flex;
            justify-content: center;
            padding-bottom: 10px !important;
            margin-left: 0 !important;

            &-active {
              background: #f5f5f5;
              border-radius: 10px 10px 0 0;
            }
          }
        }
      }
    }
  }
}

.search-assessment {
  input {
    padding: 9px 15px 9px 54px !important;
    background: #ffffff;
  }

  span {
    left: 15px;
  }
}

.add-add_chapter {
  .ant {
    &-select-selector {
      background: #f5f5f5 !important;
      border: 0 !important;
    }

    &-upload {
      height: 155px !important;
      display: flex;

      &-list {
        &-item-container {
          max-height: 155px !important;
        }

        padding: auto;

        .ant-upload-list-item {
          width: 355px !important;
          height: 155px !important;
        }
      }
    }
  }
}

//footer correction
.footer.add-chapter {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;

  .btnGroup {
    display: flex;
    justify-content: end;
    padding-left: 20px;
  }
}

.multiple-upload {
  .ant-upload {
    max-height: 155px !important;
  }
}

.bulk-question {
  .ant-select-selector,
  textarea {
    background-color: #f5f5f5 !important;
    border-color: #f5f5f5 !important;
  }
}
.course-upload {
  .ant-upload {
    &-list {
      &-item {
        &-container {
          width: 350px !important;
          height: 254px !important;
          border-color: #707070;
        }
      }
    }
  }
}
.chapter-assessment-footer {
  bottom: -50px !important;
}
.edit-chapter-content {
  position: relative;
  .search-filter {
    .material-symbols-outlined {
      top: 40%;
    }
  }
  .add-chapter {
    bottom: -110px;
    height: 80px;
  }

  .__footer {
    bottom: 3px;
  }

  ._upload {
    position: absolute;
    bottom: -132px;
    right: -28px;
  }
  ._multiple {
    bottom: -60px;
    right: -26px;
  }
  .-edit._multiple {
    position: absolute;
    bottom: -55px;
    right: -26px;
  }

  ._footer {
    padding-right: 0;
    .addButton {
      margin-right: 0;
      margin-top: 3px;
    }
  }
  .chapter-assign {
    height: 425px;
    background-color: #f5f5f5;
  }
}
.final-course {
  .ant-tabs-tabpane {
    height: 502px !important;
  }
  .common-footer {
    bottom: 0 !important;
  }
}
