.add-new {
  .ant-modal-body {
    display: unset !important;
  }

  &-course {
    .chapter {
      // min-height: 51vh;
      height: 100%;
      overflow-y: auto;

      &-content {
        width: 50%;
        padding-top: 10px;
      }

      &-criteria {
        background-color: #f5f5f5;
        overflow-y: auto;
        height: 425px;
      }
      &-assign {
        background-color: #f5f5f5;
        overflow-y: hidden;
        height: 425px;
      }
    }

    .chapter-upload-bulk {
      .add-add_chapte {
        min-height: 55vh;
      }
    }

    .chapter-content {
      margin-left: 2rem !important;
    }

    .footer.pb-4 {
      position: absolute;
      bottom: -75px;
      left: 0;
      width: 100%;
    }

    ._square {
      z-index: 9;
      position: relative;
      bottom: 20px;

      &:hover {
        color: #000;
      }
    }

    .chapter-assign.edit {
      min-height: 530px;
      .chapter-upload-bulk.question {
        min-height: 410px;
      }
      .common-footer {
        padding-right: 0;
        .confirmElms {
          margin-right: 0;
        }
      }
    }

    .bulk-question {
      min-height: 484px;

      .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
      }

      .multiple-upload {
        flex-direction: column;

        .ant-upload-wrapper {
          overflow: hidden !important;
          height: 160px !important;
          width: 150px !important;
        }

        .ant-upload-list-item-container {
          width: 150px !important;
          height: 160px !important;
        }

        .arrange-in-order {
          .ant-upload-list-item-container {
            max-width: 350px !important;
            min-width: 350px !important;
            height: 160px !important;
          }
        }
      }
    }

    .chapter-criteria {
      height: 425px;
      overflow-y: auto;
    }
  }
}

.delete-btn {
  border: 1px solid #d34a7c;
  border-radius: 50px;
  padding: 8px;
  color: #d34a7c;
}

.add-photos-btn {
  &:hover {
    border: 1px solid #d34a7c;
    background-color: #d34a7c;

    span {
      color: #fff !important;
    }
  }
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
  border-color: #d34a7c !important;
}
