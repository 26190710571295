.add-employee-modal {
    max-width: 986px;
    width: 100% !important;

    ::placeholder {
        opacity: 0 !important;
    }

    .organisation-edit {
        position: absolute;
        top: 40%;
        right: 0;
        transform: translate(0, -50%);

        .icon {
            top: -15px;
            right: -5px;
        }
    }

    .ant-tooltip {
        display: none;
    }
}

.official-email-Id {
    padding: 4px 149px !important;
}

// new design
.add-employee {
    &_requestion {
        min-width: 780px !important;
        width: 100%;
        .ant-modal-header {
            padding-left: 15px;
            border-bottom: 1px solid #00000029 !important;
            padding-bottom: 15px;
        }
    }
}
.asset-type {
    .ant-select {
        width: 325px !important;
    }
}
