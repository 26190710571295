.ant-tabs-nav {
    padding: 10px 0px 0px 0px;
}
.table-style {
    padding: 112px 5% 20px;
    &__Tab {
        box-shadow: 0px 3px 8px #0000000f;
        border-radius: 6px;
    }
}
