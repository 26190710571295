.salary {
    &_prticulars {
        &-container {
            ul {
                li {
                    color: #333333;
                    margin-bottom: 25px;
                }
            }
            ._header {
                color: #d34a7c;
                font-weight: 500;
                margin-bottom: 25px;
            }
            ._monthly,
            ._annual {
                color: #595959;
                font-weight: 500;
                margin-bottom: 25px;
            }
            .salary-gross {
                color: #333333;
                font-weight: 700;
            }
        }
    }
}
