.ant-table {
    &-cell {
        font-family: $rubik-font;
    }
    .expand-icon {
        color: #595959a8;
        cursor: pointer;
    }
    &-row {
        position: relative;
    }
    .ant-table-row-expand-icon-cell {
        position: absolute !important;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
}
