@import './ToggleMenu/ToggleMenu.scss';
@import './Notification/Notification.scss';

.header {
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1607843137);
    z-index: 8;
    min-height: 70px;

    &__menu {
        min-height: 70px;
    }

    &__leftSideMenu {
        .logo {
            margin: 0px 17px;
            width: 65px;

            @media (max-width: 767px) {
                width: 47px;
                height: 47px;
                margin: 0px 0px;
            }
        }

        p {
            margin: auto 0;
            font-size: 1.125rem;
        }
    }

    &__rightSideMenu {
        margin: auto 0;

        ul {
            li {
                &:nth-child(1) {
                    color: #595959;
                    font-size: 20px;
                    font-weight: 500;

                    @media (max-width: 767px) {
                        display: none;
                    }
                }

                margin-right: 20px;

                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }

    .ant-dropdown-menu {
        ul {
            background-color: transparent !important;
            box-shadow: unset !important;
        }
    }
}

.rejected-designation {
    color: red !important;
}
