.employee {
    &-bonus {
        max-height: 60vh;
        height: 100%;
        overflow-y: scroll;
        &-cancelled {
            color: #272727;
            font-size: 14px;
            font-weight: normal;
        }
        &-approved {
            color: #09b135;
            font-weight: 600;
            font-size: 14px;
        }
        &-confirmation {
            color: #f8832a;
            font-weight: 600;
            font-size: 14px;
        }
        &_container {
            border-bottom: 1px solid #e4e4e4;
            padding: 20px 0;
        }
        &-title {
            color: #434343;
            font-size: 16px;
            font-weight: 500;
        }
        &-date {
            font-size: 14px;
            letter-spacing: 0px;
            color: #a0a0a0;
            text-wrap: nowrap;
        }
    }
}
.add {
    &-bonus {
        width: 820px !important;
        .ant {
            &-modal {
                &-title {
                    margin-bottom: 10px;
                    &::after {
                        display: none;
                    }
                }
            }
            &-radio-wrapper {
                margin-right: 50px;
            }
        }
    }
}
