.employee {
    &-addcourse {
        width: 1000px !important;
    }
    &_name {
        color: #737373;
        font-size: 16px;
    }
    &-designation {
        color: #999999;
        font-size: 14px;
    }
    &-text {
        color: #7d7d7d;
        font-size: 14px;
    }
    &-select {
        .ant-select {
            &-selector {
                background: #efefef !important;
                border-radius: 50px !important;
                padding-left: 20px;
            }
            &-selection-item {
                border: 1px solid #d44684;
                border-radius: 50px;
                background: #fff;
                color: #d44684;
                width: 150px;
                height: 32px;
                display: flex;
                justify-content: space-around;
                align-items: center;

                &-remove {
                    color: #d44684;
                }
            }
        }
    }
}
.name-card {
    .ant {
        &-select {
            &-item {
                background-color: #fff !important;
            }
        }
    }
}
