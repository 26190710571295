.lm-tab {
    box-shadow: 0px 3px 8px #0000000f;
    border-radius: 6px;

    .lm-course {
        .ant-card {
            background: #ffffff;
            box-shadow: 0px 4px 7px #10101021;
            border-radius: 10px;

            &-card {
                max-height: 150px;
                height: 100%;
            }
        }
    }

    .ant-table-content {
        height: 60vh;
        overflow-y: auto;
    }

    .lm-table {
        .ant-table {
            &-container {
                background-color: #f5f5f5;
            }
            th,
            tr {
                background-color: #f5f5f5;
                border: none;

                td {
                    &:nth-child(2) {
                        color: #d34a7c;
                    }
                }
            }
        }
    }

    .CourseProfile {
        p {
            width: 10px;
            height: 10px;
            background-color: #d34a7c;
            border-radius: 50%;
            padding: 10px;
        }
    }

    .ant-avatar {
        margin-inline-start: -15px;
    }
}

.learning-management-tab {
    .ant {
        &-tabs {
            &-tab {
                padding: 0 !important;
            }
        }
    }
}

.chapter {
    &-upload {
        &-bulk {
            .ant-modal-body {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.add-certificate {
    width: 800px !important;
}

.not-a-delete {
    .ant-upload-list-item-actions {
        display: none;
    }
}

.checkbox-custom {
    .ant-checkbox-inner {
        width: 25px;
        height: 25px;
        border: 1px solid #cfcfcf;
    }
}

.custum-icon {
    width: 30px;
    height: 30px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e9e9e9;
}

.addCertificate-modal {
    .ant-upload-list-item {
        height: 155px !important;
    }
    .ant-select-arrow {
        margin-top: 0 !important;
    }
    .ant-modal-body {
        display: block !important;
    }

    .add-certificate-form {
        form {
            background: #f5f5f5;
            padding: 30px;
            height: 30vh;
            overflow-y: auto;

            div {
                margin-bottom: 20px;

                label,
                p {
                    font-size: 16px;
                    color: #434343;
                    font-family: 'rubik';
                }

                .ant-select {
                    width: 100%;
                }
            }
        }
    }

    .add-certificate-form-top {
        background: #f5f5f5;
        padding: 30px 30px 0px 30px;

        p {
            font-size: 16px;
            letter-spacing: 0px;
            color: #d23b7d;
        }
    }

    .ant-select-selector {
        background: #ffffff;
        border: none !important;
        padding: 25px 10px !important;
    }

    .addCertificate-type {
        .ant-select-selector {
            background-color: #f5f5f5 !important;
        }
    }
}

.leave-table.head {
    thead {
        th {
            padding: 0 16px 16px !important;
        }
        height: 0 !important;
    }
}

