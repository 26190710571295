.client {
    &-container {
        .contact-form {
            max-width: 530px;
            width: 100%;
            padding: 40px 60px;
            input {
                border-bottom: 1px solid #c5c5c5;
                outline: none;
            }
        }
        margin: 0 auto;
        &.business-plan {
            display: flex;
            align-items: center;
            .business-plans {
                margin: 0 auto;
            }
        }
    }
    &-header {
        .register {
            background-color: #ffffff;
            color: #d34a7c !important;
        }
    }
    &-banner {
        height: 120vh;
        background: linear-gradient(180deg, #ce387a 0%, #4458b2 100%);
        &-video {
            width: 100%;
            transform: translate(0%, -53%);
        }
    }
    &_banner {
        padding-top: 70px;
    }
    &-contact {
        &-form {
            background: linear-gradient(180deg, #ce387a 0%, #4458b2 100%);
        }
    }
    .key {
        &-dashboard {
            border-radius: 10px;
            &.image-1 {
                right: -100px;
                height: 350px;
                overflow: hidden;
            }
            &.image-2 {
                top: 160px;
                right: -100px;
                height: 350px;
                overflow: hidden;
            }
            &.image-3 {
                top: 320px;
                right: -100px;
                height: 350px;
                overflow: hidden;
            }
        }
        &-feature {
            &_card {
                margin-right: 30px;
            }
        }
    }
}
