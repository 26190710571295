.newLearningPath {
  .swap {
    &-questions {
      width: 40px;
      height: 40px;
      background: #d23b7d 0% 0% no-repeat padding-box;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }
  }
  .ant-collapse-item {
    margin-bottom: 6px !important;
  }
  .ant-collapse-expand-icon {
    position: absolute;
    right: 20px;
    top: 30%;
    svg {
      fill: #d34a7c;
      width: 18px;
      height: 19px;
    }
  }
  .ant-collapse-header {
    background-color: #fff0f5;
  }
  .ant-collapse-header-text {
    color: #d34a7c;
    font-size: 18px;
    font-family: Rubik, sans-serif;
  }
  .ant-checkbox-wrapper {
    margin-bottom: 0px;
    // transform: rotate(90deg);
  }
  .ant-collapse-content-box {
    h4 {
      color: #000000;
      font-weight: bold;
      font-size: 20px;
      margin-top: 10px;
    }
    p {
      background-color: #fff;
      padding: 5px;
      margin: 10px 0px 20px 0px;
      color: #838383;
      font-family: Rubik, sans-serif;
      border: 1px dashed #e9e9e9;
      font-size: 18px;
    }
  }
  .accordion-content {
    border-left: 1px dashed #d34a7c66;
    padding-left: 20px;
  }
  .ant-collapse-content {
    background-color: #f1f1f1 !important;
  }
}
.learningPathRight-content {
  padding: 20px 10px;
  font-family: "Rubik", sans-serif;

  .search-box {
    background-color: #fff;
    border: 1px solid #a0a0a0;
  }
}
.LearningList {
  li {
    font-size: 18px;
    color: #696969;
    font-family: "Rubik", sans-serif;
    label {
      padding-right: 10px;
    }
  }
}
.add-component {
  background-color: #f8f8f8;
  border: 1px solid #ececec;
  text-align: center;
  margin-top: 20px;
  color: #9c9c9c;
  font-size: 17px;
  font-family: "Rubik", sans-serif;
  cursor: pointer;
  padding: 20px 20px;
  &:hover {
    color: #d34a7c;
    border: 1px solid #d34a7c;
  }
}
.learning-management {
  tbody {
    color: #737373;
    font-family: "Rubik", sans-serif;
  }
  th {
    background-color: #fff;
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      text-align: center !important;
    }
  }
  td {
    border-bottom: 3px solid #fff !important;
    background-color: #f5f5f5;
    padding: 14px 16px !important;
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      text-align: center;
    }
  }
  .ant-table-placeholder {
    td {
      background-color: transparent !important;
    }
  }
  // .ant-pagination {
  //     display: none;
  // }
}
.assignedEmployee {
  h3 {
    color: #d34a7c;
    font-size: 23px;
    font-family: "Rubik", sans-serif;
  }
  thead {
    th {
      border-top: 1px solid #f0f0f0;
      background-color: #fff;
    }
  }
}
.profile-initials {
  background-color: #e2d3f5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  color: #8b25ff;
}
.super-dot {
  transform: rotate(90deg);
  // padding: 0 !important;
  margin-right: 10px;
  display: flex;
  justify-content: start;
  align-items: self-end;
}
.level-tab {
  height: calc(95vh - 150px);
}

.employee_addCourse_modal {
  .ant-modal .ant-modal-body {
    padding-bottom: 25px !important;
  }
}
