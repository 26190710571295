.project-page {
    .search-box {
        @media (max-width: 767px) {
            margin-right: 0px;
        }
    }
}
.search-bar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .lastSync {
        align-items: center;
        display: flex;
        font-size: 1.125rem;
        color: #d23b7d;
        p {
            display: flex;
        }
        img {
            width: 40px;
            height: 20px;
            cursor: pointer;
            color: #d23b7d;
            padding: 0px 0 0 10px;
            display: inline-block;
        }
    }
}
.projects {
    overflow-y: hidden;
    &-list {
        overflow-y: auto;
        max-height: 75vh;
        height: 100%;
        &-card {
            min-height: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
            width: 23%;
            background-color: #e3e3e3;
            border-radius: 15px;
            font-size: 1.125rem;
            color: #d23b7d;
            cursor: pointer;
            transition: all 0.2s ease;
            padding: 1rem;
            text-align: center;
            margin: 10px;
            @media (max-width: 767px) {
                width: 100%;
                margin: 10px 0px;
            }
            &:hover {
                background-color: #d23b7d;
                color: #ffffff;
            }
        }
    }
    &-board {
        .ant-collapse {
            &-header {
                position: relative;
                height: 50px;
                background-color: #fdf2f7;
                border: 1px solid #ededed;
            }
            &-expand-icon {
                position: absolute;
                right: 10px;
            }
        }
    }
}
.project-board-details {
    .card_ {
        height: auto !important;
        // margin: 10px;
        -webkit-overflow-scrolling: auto;

        .project-sprint {
            display: flex;
            justify-content: space-between;

            .totslSprintDays {
                span {
                    padding: 0 4px;
                }
            }
        }

        .employeeScore {
            width: 100%;
            display: flex;
            margin: 0 0 10px 0;

            .scoreDetail {
                width: 15%;
                margin: 10px;
                text-align: center;
                padding: 5px;
                border-radius: 10px;
                // border: 1px solid pink;
                background-color: #fdf2f7;

                p {
                    margin: 0;
                    color: #d34a7c;
                    padding-top: 10px;
                    text-align: center;
                }
            }

            .exceptionalScore {
                width: 10%;
                margin: 10px;
                padding: 5px;
                text-align: center;
                color: green;
                border-radius: 10px;
                background-color: #d3ffd3;

                p {
                    margin: 0;
                    color: green;
                }
            }

            .exceptionalAvg {
                width: 10%;
                margin: 10px;
                padding: 5px;
                text-align: center;
                color: #a15601;
                border-radius: 10px;
                background-color: #ffcfa0;

                p {
                    margin: 0;
                    color: #a15601;
                }
            }

            .belowAvg {
                width: 10%;
                margin: 10px;
                padding: 5px;
                text-align: center;
                color: #db444b;
                border-radius: 10px;
                background-color: #ffc3c6;

                p {
                    margin: 0;
                    color: green;
                }
            }

            .goodScore {
                width: 10%;
                margin: 10px;
                padding: 5px;
                text-align: center;
                color: #c79f00;
                border-radius: 10px;
                background-color: #fcedb3;

                p {
                    margin: 0;
                    color: #c79f00;
                }
            }
        }
    }

    .projectSprint {
        background-color: #fdf2f7;
        color: #d34a7c;
        height: 40px;
    }

    .details-card-main {
        max-height: 70vh;
        height: 100%;
        overflow-y: auto;

        .project-sprint {
            border: 1px solid #ededed;
            background-color: #fdf2f7;
            color: #d23b7d;
            height: 40px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            font-weight: 500;

            span {
                color: #d34a7c;
                background-color: #fdf2f7;
                width: auto;
            }
        }

        .asignee {
            th {
                border: 2px solid #E5E5E5;
            }
        }

        .performance-res {
            margin-top: 30px;
            border: 1px solid #ededed;
        }

        td {
            color: #595959;
        }

        .emp-name {
            text-align: left;
            background-color: #ffffff;
            color: #222;
        }

        .totalPercentage {
            background-color: #ebf3ff;

            td {
                color: #2c5ba7;
            }
        }
    }

    th {
        font-weight: 500;
        border: 1px solid #e3dbdb;
        background-color: #f4f4f4;
    }

    table {
        border-collapse: collapse;
        border: 1px solid #f4f4f4;
        span {
            display: inline-block;
            /* padding: 20px; */
            width: 65%;
            padding: 5px;
        }
        .color {
            &-green {
                background-color: #d3ffd3;
            }
            &-yellow {
                background-color: #fcedb3;
            }
            &-orange {
                background-color: #ffcfa0;
            }
            &-red {
                background-color: #ffc3c6;
            }
        }
    }
    table,
    td,
    th {
        border-bottom: 2px solid #f5f5f5;
        text-align: center;
        letter-spacing: 0px;
        color: #595959;
        opacity: 1;
        border-top: 2px solid #f5f5f5;
        height: 48px;
        font-weight: 500;
        // border: #F5F5F5;
    }
}

@media (max-width: 768px) {
    .project-board-details {
        .employeeScore {
            display: block !important;

            div {
                width: 100% !important;
                margin: 0px 0px 10px 0px !important;
            }
        }
    }

    .asignee {
        th {
            padding: 10px;
        }
    }
}
